
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as callback0kpciBF0MpMeta } from "/opt/render/project/src/packages/authentication/client/pages/auth/callback.vue?macro=true";
import { default as loginSnsKsqLgDzMeta } from "/opt/render/project/src/apps/learner/client/pages/auth/login.vue?macro=true";
import { default as verifyGVVar1ZWy2Meta } from "/opt/render/project/src/packages/authentication/client/pages/auth/v1/verify.vue?macro=true";
import { default as iamhereIyWENEONg7Meta } from "/opt/render/project/src/apps/learner/client/pages/iamhere.vue?macro=true";
import { default as _91sessionId_93ESk3UfgjRUMeta } from "/opt/render/project/src/apps/learner/client/pages/index/sessions/[sessionId].vue?macro=true";
import { default as sessionslCJGQLCuQVMeta } from "/opt/render/project/src/apps/learner/client/pages/index/sessions.vue?macro=true";
import { default as indexptkpx1Unr6Meta } from "/opt/render/project/src/apps/learner/client/pages/index.vue?macro=true";
import { default as _91sessionId_93ie3TJQdALwMeta } from "/opt/render/project/src/apps/learner/client/pages/satisfaction/[sessionId].vue?macro=true";
export default [
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: callback0kpciBF0MpMeta || {},
    component: () => import("/opt/render/project/src/packages/authentication/client/pages/auth/callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginSnsKsqLgDzMeta || {},
    component: () => import("/opt/render/project/src/apps/learner/client/pages/auth/login.vue")
  },
  {
    name: "auth-v1-verify",
    path: "/auth/v1/verify",
    meta: verifyGVVar1ZWy2Meta || {},
    component: () => import("/opt/render/project/src/packages/authentication/client/pages/auth/v1/verify.vue")
  },
  {
    name: "iamhere",
    path: "/iamhere",
    meta: iamhereIyWENEONg7Meta || {},
    component: () => import("/opt/render/project/src/apps/learner/client/pages/iamhere.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexptkpx1Unr6Meta || {},
    redirect: indexptkpx1Unr6Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/learner/client/pages/index.vue"),
    children: [
  {
    name: "index-sessions",
    path: "sessions",
    component: () => import("/opt/render/project/src/apps/learner/client/pages/index/sessions.vue"),
    children: [
  {
    name: "index-sessions-sessionId",
    path: ":sessionId()",
    component: () => import("/opt/render/project/src/apps/learner/client/pages/index/sessions/[sessionId].vue")
  }
]
  }
]
  },
  {
    name: "satisfaction-sessionId",
    path: "/satisfaction/:sessionId()",
    meta: _91sessionId_93ie3TJQdALwMeta || {},
    component: () => import("/opt/render/project/src/apps/learner/client/pages/satisfaction/[sessionId].vue")
  }
]